<template>
	<!--서브탑-->
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>ABOUT KSA</p>
				<span>대한서핑협회를 소개 합니다.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>

	<!--서뷰메뉴-->
	<div class="sub-menu" style="margin-top: 15px;">
		<ul class="float-wrap">
			<!-- <li class="on"><router-link to="/about-greeting">인사말</router-link></li>
			<li><router-link to="/about-ksa">협회소개</router-link></li>
			<li><router-link to="/about-organization">조직도</router-link></li>
			<li><router-link to="/about-rules">시/도협회</router-link></li> -->

			<div class="tab-container tab-box">
				<div class="tab tab-4">
					<button type="button" @click="setTab(1)" :class="''">인사말</button>
					<button type="button" @click="setTab(2)" :class="'on'">협회소개</button>
					<button type="button" @click="setTab(3)" :class="''">조직도</button>
					<button type="button" @click="setTab(4)" :class="''">시/도협회</button>
				</div>
			</div>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap sub-wrap-grey sub-wrap-npb">
		<div class="sub-cont">

			<!--협회소개-->
			<div class="about float-wrap">
				<div class="about-img"><img src="@/assets/images/img/img-about.png"></div>
				<div class="about-cont">
					<div class="about-desc">
						<h5>KSA(대한서핑협회)소개</h5>
						<p>대한서핑협회는 국내 서핑의 저변 확대를 통하여 <br>
							해양레저스포츠인 서핑의 기술 향상과 대한민국 서핑 단체에 <br>
							국제서핑협회의 룰을 정식 보급하고 우수한 지도자와 선수를 <br>
							양성하며 국제대회 선수파견을 통한 국위 선양 및 서퍼 <br>
							상호간의 협력을 바탕으로 국민체육진흥 발전에 <br>
							기여함을 목적으로 한다.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="sub-cont sub-cont-full">
			<!--연혁-->
			<div class="tab-container tab-block">

				<div class="tab">
					<button type="button" class="on">연혁</button>
					<!--
          <button type="button">2017년 - 2021년</button>
          <button type="button">2013년 - 2016년</button>
          <button type="button">2008년 - 2012년</button>
          -->
				</div>

				<div class="tab-contents">
					<!--탭내용-->
					<div class="tab-cont active">
						<div class="table-top">
							<p class="table-top-title">2024년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2024.10월</th>
									<td>경상북도 포항시서핑페스티벌 승인</td>
								</tr>
								<tr>
									<th>2024.10월</th>
									<td>경상북도 울진군전국서핑대회 승인</td>
								</tr>
								<tr>
									<th>2023.09월</th>
									<td>월드SUP패들보드챔피언십 국가대표팀 파견</td>
								</tr>
								<tr>
									<th>2023.08월</th>
									<td>전라남도 고흥우주항공배 전국서핑대회 승인</td>
								</tr>
								<tr>
									<th>2023.06월</th>
									<td>제주특별자치도 제주오픈 개최</td>
								</tr>
								<tr>
									<th>2023.05월</th>
									<td>경기도 시흥시장배 국제서핑대회 승인</td>
								</tr>
								<tr>
									<th>2023.04월</th>
									<td>월드롱보드서핑챔피언십 국가대표팀 파견</td>
								</tr>
								<tr>
									<th>2023.04월</th>
									<td>포항메이어스컵챔피언십 승인</td>
								</tr>
								<tr>
									<th>2023.02월</th>
									<td>월드서핑게임 국가대표팀 파견</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2023년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2023.07월</th>
									<td>아시안서핑챔피언십 몰디브 주니어 국가대표팀 파견</td>
								</tr>
								<tr>
									<th>2023.06월</th>
									<td>월드서핑게임, 엘살바도르 국가대표팀 파견</td>
								</tr>
								<tr>
									<th>2023.06월</th>
									<td>부산광역시장배 국제서핑대회 승인</td>
								</tr>
								<tr>
									<th>2023.05월</th>
									<td>대한서핑협회장배 국가대표선발전 개최</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2022년</p>
							<!--
								<div class="table-top-btns">
									<button type="button" class="btn btn-sm btn-basic btn-icon-right">다운로드<img
										src="@/assets/images/icon/icon-download.png"></button>
									<button type="button" class="btn btn-sm btn-basic btn-icon"><img src="@/assets/images/icon/icon-print.png"
										alt="프린트"></button>
								</div>
							-->
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2022.11월</th>
									<td>대한서핑협회장배서핑대회(주관)</td>
								</tr>
								<tr>
									<th>2022.10월</th>
									<td>2022포항국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2022.10월</th>
									<td>시흥시서핑협회배 서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2022.09월</th>
									<td>2022 ISA월드서핑게임(파견)</td>
								</tr>
								<tr>
									<th>2022.09월</th>
									<td>2022대전충청서핑페스티벌(승인)</td>
								</tr>
								<tr>
									<th>2022.09월</th>
									<td>2022울산진하서핑페스티벌(승인)</td>
								</tr>
								<tr>
									<th>2022.09월</th>
									<td>해운대구청장배 서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2022.08월</th>
									<td>2022고흥남열 전국서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2022.05월</th>
									<td>2022부산광역시장배 국제서핑/SUP대회(승인)</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2021년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2021.11월</th>
									<td>2021 포항시장배 서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2021.06월</th>
									<td>제12회 부산광역시장배 국제서핑대회(승인)</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2020년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2020.12월</th>
									<td>2020 포항시장배 서핑대회(승인)</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2019년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2019.10월</th>
									<td>2019 울산진하서핑페스티벌(승인)</td>
								</tr>
								<tr>
									<th>2019.10월</th>
									<td>제2회 포항 메이어스컵전국서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2019.10월</th>
									<td>제6회 양양 국제서핑페스티벌(승인)</td>
								</tr>
								<tr>
									<th>2019.10월</th>
									<td>제1회 고흥남열 전국서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2019.09월</th>
									<td>2019 ISA월드서핑게임(파견)</td>
								</tr>
								<tr>
									<th>2019.07월</th>
									<td>ISA SUP 국제강사과정 연수(교육)</td>
								</tr>
								<tr>
									<th>2019.06월</th>
									<td>제11회 부산광역시장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2019.06월</th>
									<td>제1회 대한서핑협회장배 전국서핑대회 및 국가대표선발전(주관)</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2018년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2018.12월</th>
									<td>ISA 국제 코칭 Lv.1 과정(교육)</td>
								</tr>
								<tr>
									<th>2018.10월</th>
									<td>ISA 국제심판 Lv.1 과정(교육)</td>
								</tr>
								<tr>
									<th>2018.10월</th>
									<td>제1회 포항 메이어스컵전국서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2018.10월</th>
									<td>아시아서핑연맹 총회 (아사아서핑연맹 발족)</td>
								</tr>
								<tr>
									<th>2018.10월</th>
									<td>제5회 양양 국제 서핑 페스티벌(승인)</td>
								</tr>
								<tr>
									<th>2018.09월</th>
									<td>2018 ISA월드서핑게임(파견)</td>
								</tr>
								<tr>
									<th>2018.09월</th>
									<td>제14회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2018.07월</th>
									<td>제9회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2018.06월</th>
									<td>제10회 부산광역시장배 국제서핑대회(승인)</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2017년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2017.10월</th>
									<td>제4회 해양수산부배 양양 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2017.09월</th>
									<td>제13회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2017.07월</th>
									<td>제8회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2017.06월</th>
									<td>제9회 부산광역시장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2017.05월</th>
									<td>2017 ISA 월드서핑게임(파견)</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2016년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2016.10월</th>
									<td>제3회 양양 국제 서핑 페스티발(승인)</td>
								</tr>
								<tr>
									<th>2016.10월</th>
									<td>제1회 반려견 서핑 페스티발(승인)</td>
								</tr>
								<tr>
									<th>2016.09월</th>
									<td>제1회 다이아몬드 브릿지 SUP챔피언쉽(자체)</td>
								</tr>
								<tr>
									<th>2016.09월</th>
									<td>제12회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2016.07월</th>
									<td>제7회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2016.06월</th>
									<td>제8회 부산광역시장배 국제서핑대회 개최</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2015년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2015.10월</th>
									<td>제2회 양양 국제서핑 페스티벌(승인)</td>
								</tr>
								<tr>
									<th>2015.09월</th>
									<td>제11회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2015.07월</th>
									<td>제6회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2015.06월</th>
									<td>제7회 부산광역시장배 국제서핑대회 개최</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2014년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2014.10월</th>
									<td>제1회 해양수산부배 양양 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2014.09월</th>
									<td>제10회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2014.07월</th>
									<td>제5회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2014.06월</th>
									<td>제6회 부산광역시장배 국제서핑대회 개최</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2013년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2013.09월</th>
									<td>제9회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2013.07월</th>
									<td>제4회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2013.06월</th>
									<td>제5회 부산광역시장배 국제서핑대회 개최</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2012년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2012.09월</th>
									<td>제8회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2012.07월</th>
									<td>제3회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2012.06월</th>
									<td>제4회 부산광역시장배 국제서핑대회 개최</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2011년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2011.09월</th>
									<td>제7회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2011.07월</th>
									<td>제2회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2011.06월</th>
									<td>제3회 부산광역시장배 국제서핑대회 개최</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2010년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2010.09월</th>
									<td>제6회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2010.07월</th>
									<td>제1회 제주-쇠소깍 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2010.06월</th>
									<td>제2회 부산광역시장배 국제서핑대회 개최</td>
								</tr>
								<tr>
									<th>2010.02월</th>
									<td>대한서핑연맹 ISA 국제연맹의 방침으로 혐회로 명변경</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2009년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2009.09월</th>
									<td>제5회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2009.06월</th>
									<td>제1회 부산광역시장배 국제서핑대회(자체)</td>
								</tr>
								<tr>
									<th>2009.06월</th>
									<td>ISD(International Surfing Day)국제 서핑의 날 개최</td>
								</tr>
								<tr>
									<th>2009.06월</th>
									<td>모래축제 서핑 강습회 개최</td>
								</tr>
								<tr>
									<th>2009.02월</th>
									<td>국제서핑협회(International Surfing Association) 정가맹</td>
								</tr>
								<tr>
									<th>2009.01월</th>
									<td>대한서핑협회 사단법인 등록</td>
								</tr>
							</table>
						</div>
						<div class="table-top">
							<p class="table-top-title">2008년</p>
						</div>
						<div class="table-1 table-overflow-sm">
							<table>
								<colgroup>
									<col style="width:18%;">
									<col style="width:auto;">
								</colgroup>
								<tr>
									<th>2008.09월</th>
									<td>JAPAN FUKUOKA SURF GIRL 대회 선수파견</td>
								</tr>
								<tr>
									<th>2008.09월</th>
									<td>제4회 해운대구청장배 국제서핑대회(승인)</td>
								</tr>
								<tr>
									<th>2008.06월</th>
									<td>ISD(International Surfing Day)국제 서핑의 날 개최</td>
								</tr>
								<tr>
									<th>2008.03월</th>
									<td>대한레저스포츠 협의회 단체 등록 인증</td>
								</tr>
								<tr>
									<th>2008.02월</th>
									<td>대한서핑연맹 발족</td>
								</tr>
							</table>
						</div>
					</div>

					<!--탭내용-->
					<div class="tab-cont"></div>

					<!--탭내용-->
					<div class="tab-cont"></div>

					<!--탭내용-->
					<div class="tab-cont"></div>
				</div>
			</div>
		</div>
	</div>

	<!--하단 이미지-->
	<!--
  <div class="sub-wrap sub-wrap-grey sub-wrap-np">
    <div class="bottom-banners">
      <ul>
        <li><img src="@/assets/images/img/img-bottom.png"></li>
        <li><img src="@/assets/images/img/img-bottom.png"></li>
        <li><img src="@/assets/images/img/img-bottom.png"></li>
        <li><img src="@/assets/images/img/img-bottom.png"></li>
        <li><img src="@/assets/images/img/img-bottom.png"></li>
      </ul>
    </div>
  </div>
 -->
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

let { tree } = store.state.treeMenu
// console.log(tree)

//탭
const activeTabId = ref(1)

const setTab = (tabId) => {
	activeTabId.value = tabId
	if (tabId == 1) {
		router.push({ path: '/about-greeting' })
	}
	if (tabId == 2) {
		router.push({ path: '/about-ksa' })
	}
	if (tabId == 3) {
		router.push({ path: '/about-organization' })
	}
	if (tabId == 4) {
		router.push({ path: '/about-rules' })
	}
}

</script>

<style></style>